


















































































































import { Nav, Order } from "@/graphql/client";
import { Component, Emit, Mixins } from "vue-property-decorator";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import orderService from "@/service/orderService";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class NavViewDialog extends Mixins(MixinFormatter) {
  //---------------------------
  // data
  //---------------------------
  items: Nav[] = [];
  order: Order = orderService.defaultOrder;

  isOpen = false;
  isProgressing = false;

  isLoading = true;


  headers = [
    {
      text: "配送先連番",
      value: "id",
      width: "100px",
    },
    {
      text: "氏名",
      value: "deliveryName",
      width: "150px",
    },
    {
      text: "郵便番号",
      value: "deliveryPostalCode",
      width: "100px",
    },
    {
      text: "住所",
      value: "deliveryAddress",
      width: "380px",
    },
    {
      text: "お届け商品",
      value: "item",
    },
  ];
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  private async fetchData(input: Order): Promise<void> {
    this.isLoading = true;
    this.items = [];
    this.order = orderService.defaultOrder;

    //表示データ取得
    if (input.guid) {
      const order = await orderService.getOrder(input.guid);
      if (order) {
        this.order = order;
      }

      await orderService.getNavs(input.guid).then((items) => {
        if (items) {
          this.items = items;
        }
      });
    }
    this.isLoading = false;
  }

  /**
   * ダイアログを表示します.
   */
  public open(item: Order): void {
    this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }
}
