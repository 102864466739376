


























































































































































import { Delivery, DeliveryDetail, Item, Order, OrderOptionPropertyValue, PropertyType } from "@/graphql/client";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import appService from "@/service/appService";
import { Component, ModelSync, Mixins, Prop } from "vue-property-decorator";
import OrderOptionCards from "@/components/molecules/order/OrderOptionCards.vue";
import orderService from "@/service/orderService";


@Component({
  components: {
    OrderOptionCards,
  },
})
export default class DeliveryCards extends Mixins(MixinFormatter) {
  @ModelSync("order", "updateOrder", { type: Object, required: true })
  readonly orderValue!: Order;

  @Prop({ type: Object, required: true })
  navItemLineNumberMap!: {[key: string]: number};
  //---------------------------
  // data
  //---------------------------
  prefectures = appService.prefectures;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  public getNavItemLineNumber(delivery: Delivery | null, detail: DeliveryDetail | null) {
    if (delivery == null || detail == null) return 0;

    if (this.navItemLineNumberMap) {
      const navItemLineMap = this.navItemLineNumberMap;
      const key = delivery.id + '_' + detail?.webCode;
      if ( navItemLineMap && navItemLineMap[key] ) {
        return '#' + navItemLineMap[key];
      }
    }
    return '';
  }
  public showOptionImage(imageName: string, imageData:string) {
    //画像を別ウィンドウで表示
    let win = window.open('about:blank', '_blank');
    if (win) {
      win.document.title = imageName;
      win.document.write("<h1>" + imageName + "</h1><img src='" + imageData + "' title=" + imageName + " />");
    }
  }

  isImageProperty(prop: OrderOptionPropertyValue) {
    if (prop.propertyType == PropertyType.OriginalMessageCard) return true;
    if (prop.propertyType == PropertyType.MaterialImage) return true;
    return false;
  }
  public calcDeliveryTotalPrice(delivery: Delivery): number {
    let totalPriceWithTax = 0;
    if (delivery.deliveryDetails && delivery.deliveryDetails.length > 0) {
      delivery.deliveryDetails.forEach((detail) => {
        if (detail?.priceWithTax) {
          totalPriceWithTax = totalPriceWithTax + detail.priceWithTax;
        }
      });
    }
    if (delivery.postageFeeWithTax) {
      totalPriceWithTax = totalPriceWithTax + delivery.postageFeeWithTax;
    }
    return totalPriceWithTax;
  }
}
