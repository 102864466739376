















































































































































































































































































































import { Component, Ref, Mixins, Prop, Watch } from "vue-property-decorator";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinSearchTimer from "@/components/mixins/MixinSearchTimer.vue";
import MixinOrderAction from "@/components/mixins/order/MixinOrderAction.vue";
import { HandoverMessage, Member, Order, OrderFilter, OrderState, PaymentMethodType } from "@/graphql/client";
import orderService from "@/service/orderService";
import { NotificationState } from "@/store/modules/notification";
import OrderEditDialog from "@/components/organisms/order/OrderEditDialog.vue";
import OrderCsvUploadDialog from "@/components/organisms/order/OrderCsvUploadDialog.vue";
import OrderCommunicationMessageDialog from "@/components/organisms/order/OrderCommunicationMessageDialog.vue";
import OrderHandoverMessageDialog from "@/components/organisms/order/OrderHandoverMessageDialog.vue";
import OrderStatusChageDialog from "@/components/organisms/order/OrderStatusChageDialog.vue";
import NavFileUploadDialog from "@/components/organisms/order/NavFileUploadDialog.vue";
import NavViewDialog from "@/components/organisms/order/NavViewDialog.vue";
import typeService from "@/service/typeService";
import OrderUploadDeliverySlipNoDialog from "./OrderUploadDeliverySlipNoDialog.vue";
import moment from "moment";

@Component({
  components: {
    OrderEditDialog,
    OrderCsvUploadDialog,
    OrderCommunicationMessageDialog,
    OrderHandoverMessageDialog,
    OrderStatusChageDialog,
    NavFileUploadDialog,
    OrderUploadDeliverySlipNoDialog,
    NavViewDialog,
  },
})
export default class OrderList extends Mixins(
  MixinFormatter,
  MixinSearchTimer,
  MixinOrderAction
) {
  @Ref() readonly editDialog!: OrderEditDialog;
  @Ref() readonly uploadDialog!: OrderCsvUploadDialog;
  @Ref() readonly navFileUploadDialog!: NavFileUploadDialog;
  @Ref() readonly communicationDialog!: OrderCommunicationMessageDialog;
  @Ref() readonly handoverDialog!: OrderHandoverMessageDialog;
  @Ref() readonly uploadDeliverySlipNoDialog!: OrderUploadDeliverySlipNoDialog;
  @Ref() readonly navViewDialog!: NavViewDialog;

  @Prop() orderGuid?: string;
  //---------------------------
  // data
  //---------------------------
  items: Order[] = [];
  headers = [
    {
      text: "注文番号",
      value: "id",
      width: "120px",
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "handover",
      width: "20px",
      sortable: false,
    },
    {
      text: "",
      value: "download",
      width: "20px",
      sortable: false,
    },
    {
      text: "",
      value: "viewNav",
      width: "30px",
      sortable: false,
    },
    {
      text: "購入日時",
      value: "transactionDate",
      width: "10%",
      formatter: this.formatDateTime,
    },
    {
      text: "購入金額",
      value: "totalPriceWithTax",
      width: "120px",
      formatter: this.formatPrice,
    },
    {
      text: "決済方法",
      value: "paymentMethodType",
      width: "10%",
      formatter: this.getPaymentMethodTypeLabel,
    },
    {
      text: "ステータス",
      value: "status",
      width: "10%",
      formatter: this.getOrderStateLabel,
    },
    {
      text: "",
      value: "changeStatus",
      width: "20px",
      sortable: false,
    },
    {
      text: "",
      value: "communication",
      width: "20px",
      sortable: false,
    },
    {
      text: "送り主",
      value: "senderName",
      width: "150px",
      sortable: false,
    },
    {
      text: "購入",
      value: "orderHistories",
      width: "50px",
    },
    {
      text: "メールアドレス",
      value: "member.email",
      width: "10%",
    },
    {
      text: "配送指定",
      value: "deliveryDates",
    },
  ];

  loadingDataGrid = false;

  searchKeyword = "";
  memberId = "";
  paymentMethodTypes = typeService.paymentMethodTypes;
  orderStatus = typeService.orderStatus;

  transactionDateFromMenu = false;
  transactionDateToMenu = false;

  paymentMethodType = null;
  orderId = null;
  orderState = null;
  transactionDateFrom = null;
  transactionDateTo = null;


  totalPriceFrom = null;
  totalPriceTo = null;
  memberName = null;
  phoneNumber = null;

  //pagenation
  totalOrders = 0;
  orderDataOptions:{
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
    groupBy: string[];
    groupDesc: boolean[];
  } = {
    page: 1,
    itemsPerPage: 20,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
  };
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.searchOrders();

    //orderGuid prop が設定されている場合はダイアログを表示
    if (this.orderGuid != undefined) {
      orderService.getOrder(this.orderGuid).then((item) => {
        this.openEditDialog(item);
      });
    }
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  @Watch('orderDataOptions')
  public onChangeOrderDataOptions() {
    this.searchTimeOut(this.searchOrders);
  }

  @Watch('transactionDateFrom')
  public onChangeTransactionDateFrom() {
    console.log('test');
    this.searchTimeOut(this.searchOrders);
  }
  @Watch('transactionDateTo')
  public onChangeTransactionDateTo() {
    this.searchTimeOut(this.searchOrders);
  }
  @Watch('paymentMethodType')
  public onChangePaymentMethodType() {
    this.searchTimeOut(this.searchOrders);
  }
  @Watch('orderState')
  public onChangeOrderState() {
    this.searchTimeOut(this.searchOrders);
  }
  @Watch('orderId')
  public onChangeOrderId() {
    this.searchTimeOut(this.searchOrders);
  }
  @Watch('totalPriceFrom')
  public onChangeTotalPriceFrom() {
    this.searchTimeOut(this.searchOrders);
  }
  @Watch('totalPriceTo')
  public onChangeTotalPriceTo() {
    this.searchTimeOut(this.searchOrders);
  }
  @Watch('memberName')
  public onChangeMemberName() {
    this.searchTimeOut(this.searchOrders);
  }
  @Watch('memberId')
  public onChangeMemberId() {
    this.searchTimeOut(this.searchOrders);
  }
  @Watch('phoneNumber')
  public onChangePhoneNumber() {
    this.searchTimeOut(this.searchOrders);
  }

  public orderDeliveryDates(order: Order) {
    let deliveryDates: any[] = [];
    if(order.deliveries) {
      //無効な明細は除外
      const deliveries = order.deliveries.filter(delivery => {
        return delivery?.disabled == false;
      });
      deliveries.forEach((item) => {
        if (item?.deliveryDate) {
          let dateStr = this.formatDate(item.deliveryDate, "MM/DD");
        if (deliveryDates.indexOf(dateStr) < 0) {
          deliveryDates.push(dateStr);
        }
        }
      });
    }
    return deliveryDates.join('、 ');
  }

  public unreadHandoverMessageCount(order: Order) {
    if(order.handoverMessages) {
      let filtered =order.handoverMessages.filter((item) => {
        return !item?.readDate;
      });
      return filtered.length;
    }
    return 0;
  }
  public hasUnreadHandoverMessage(order: Order) {
    return this.unreadHandoverMessageCount(order) > 0;
  }

  /**
   * 注文者の注文履歴数を表示
   */
  public memberOrderHistoryCount(order: Order) {
    if ( order && order.member && order.member.orders ) {
      return order.member.orders.length;
    }
    return 0;
  }
  /**
   * 注文を検索
   */
  public searchOrders(): void
  {
    const { sortBy, sortDesc, page, itemsPerPage } = this.orderDataOptions;


    let filter: OrderFilter = {};
    //デフォルトで削除済の注文は除外
    let excludeStatus = [ OrderState.Deleted ];

    if (this.transactionDateFrom) {
      //UTCに変換
      filter.transactionDateFrom = moment(this.transactionDateFrom+' 00:00').utc().format();
    } else {
      filter.transactionDateFrom = undefined;
    }

    if (this.transactionDateTo) {
      //UTCに変換
      filter.transactionDateTo = moment(this.transactionDateTo+' 00:00').add(1,'d').utc().format();
    } else {
      filter.transactionDateTo = undefined;
    }
    if (this.orderState) {
      filter.status = this.orderState;
      if (this.orderState == OrderState.Deleted) {
        //削除済のステータスを指定した場合は、除外しない
        excludeStatus = [];
      }
    }
    if (this.orderId) {
      filter.id = this.orderId;
    }
    if (this.paymentMethodType) {
      filter.paymentMethodType = this.paymentMethodType;
    }
    if (this.memberId) {
      filter.memberId = this.memberId;
    }

    if (this.memberName) {
      filter.memberName = this.memberName;
    }

    if (this.phoneNumber) {
      filter.phoneNumber = this.phoneNumber;
    }

    if (this.totalPriceFrom) {
      filter.totalPriceWithTaxFrom = this.totalPriceFrom;
    }
    if (this.totalPriceTo) {
      filter.totalPriceWithTaxTo = this.totalPriceTo;
    }

    if (excludeStatus.length > 0) {
      filter.excludeOrderStatus = excludeStatus;
    }

    let skip = ( page - 1 ) * itemsPerPage
    let take = itemsPerPage


    this.loadingDataGrid = true;
    //トータル取得
    orderService.countOrders(filter).then((totalNum) => {
      this.totalOrders = totalNum;
      //検索結果取得
      orderService.searchOrders(filter, skip, take).then((items) => {
        this.items = items;
        this.loadingDataGrid = false;
      });
    });
  }

  public orderActions(item: Order): {
    value: string;
    label: string;
    beforeOrderStatus: OrderState[];
  }[] {
    return orderService.getNextActions(item);
  }
  /**
   * 注文を一覧CSVをダウンロードします.
   */
  public exportCSV(): void {
    // orderService.exportCSV();
  }
  /**
   * 受注売上伝票Excel（商品ごと）をダウンロードします.
   */
  public downloadSalesSlipExcel(item: Order): void {
    if (item.guid) {
      orderService.downloadSalesSlipExcel(item);
    }
  }
  /**
   * Navファイルをダウンロードします.
   */
  public downloadNavFile(item: Order): void {
    if (item.guid) {
      orderService.downloadNavFile(item);
    }
  }
  /**
   * NP後払い請求CSVをダウンロードします.
   */
  public downloadNPCsvFile(item: Order): void {
    if (item.guid) {
      orderService.downloadNPCsvFile(item);
    }
  }

  /**
   * NAVファイルアップロードダイアログを表示します.
   */
  public openNavFileUploadDialog(): void {
    this.navFileUploadDialog.open();
  }
  /**
   * NAVファイルアップロードダイアログの処理成功時.
   */
  public onNavFileUploadSuccess(value: string): void {
    console.log(value);
    this.navFileUploadDialog.close();
    NotificationState.notifySuccess("注文を更新しました！");
  }
  /**
   * 送り状番号CSVアップロードダイアログを表示します.
   */
  public openUploadDeliverySlipNoDialog(): void {
    this.uploadDeliverySlipNoDialog.open();
  }
  public onUploadDeliverySlipNoSuccess(): void {
    this.uploadDeliverySlipNoDialog.close();
    NotificationState.notifySuccess("配送伝票番号をアップロードしました!");
  }

  /**
   * 注文CSVアップロードダイアログを表示します.
   */
  public openUploadDialog(): void {
    this.uploadDialog.open();
  }
  /**
   * 注文アップロードダイアログの処理成功時.
   */
  public onUploadSuccess(value: string): void {
    this.uploadDialog.close();
    NotificationState.notifySuccess("注文を更新しました！");
  }

  /**
   * 注文編集ダイアログを表示します.
   */
  public openEditDialog(item: Order): void {
    this.editDialog.open(item);
  }

  /**
   * 注文編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(value: string): void {
    console.log(value);
    this.editDialog.close();
    NotificationState.notifySuccess("注文を更新しました！");
  }

  /**
   * 配送伝票形式のダイアログを表示します.
   */
  public openNavViewDialog(item: Order): void {
    this.navViewDialog.open(item);
  }


  /**
   * コミュニケーションメッセージダイアログを表示します.
   */
  public openCommunicationMessageDialog(item: Order): void {
    this.communicationDialog.open(item);
  }

  /**
   * 申し送りメッセージダイアログを表示します.
   */
  public openHandoverMessageDialog(item: Order): void {
    this.handoverDialog.open(item);
  }

  public async updateUnleadHandoverMessageCount(updated: Order): Promise<void> {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }
  }

  /**
   * 注文ステータス変更ダイアログの更新成功時.
   */
  onStatusChangeSuccess(updated: Order): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.statusChangeDialog.close();
    NotificationState.notifySuccess("注文ステータスを変更しました！");
  }

  /**
   * 注文ステータス変更NG
   */
  onStatusChangeError(message: string): void {
    this.statusChangeDialog.close();
    NotificationState.notifyError(message);
  }
}
